const initialState = {
  fetching: false,
  fetched: false,
  token: {},
  error: null
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOKEN_PENDING':
      return { ...state, fetching: true }
    case 'TOKEN_FULFILLED':
      return { ...state, fetching: false, fetched: true, token: action.payload }
    case 'TOKEN_REJECTED':
      return { ...state, fetching: false, error: action.payload }
    default:
      return state
  }
}
export default tokenReducer; 
