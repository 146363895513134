const initialState = {
  fetching: false,
  fetched: false,
  response: [],
  error: null,
};

const fetchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PENDING":
      return { ...state, fetching: true };
    case "FETCH_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        response: action.payload,
      };
    case "FETCH_REJECTED":
      window.localStorage.removeItem("key");
      window.location.reload();
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
};
export default fetchReducer;
