const initialState = {
  fetching: false,
  fetched: false,
  users: [],
  error: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_PENDING':
      return { ...state, fetching: true }
    case 'USER_FULFILLED':
      return { ...state, fetching: false, fetched: true, users: action.payload }
    case 'USER_REJECTED':
      return { ...state, fetching: false, error: action.payload }
    default:
      return state
  }
}
export default userReducer; 
