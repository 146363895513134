import React from 'react';
import "./preloader.css"

const Preloader = () => (
    <div class="preloader">
		<div class="loader">
			<div class="shadow"></div>
			<div class="box"></div>
		</div>
	</div>
);

export default Preloader;