import { combineReducers } from 'redux'
import userReducer from './user'
import fetchReducer from './fetch'
import headerReducer from './header'
import tokenReducer from './token'

export default combineReducers({
  userReducer,
  fetchReducer,
  headerReducer,
  tokenReducer
});